import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"


const firebaseConfig = {
  apiKey: "AIzaSyBIdEkRWG1C1nYoB35Tty2NZHMtc0SV4CA",
  authDomain: "grace-16c64.firebaseapp.com",
  projectId: "grace-16c64",
  storageBucket: "grace-16c64.appspot.com",
  messagingSenderId: "650804981171",
  appId: "1:650804981171:web:f74f5ab59f5900b3852fc6",
  measurementId: "G-HWEBTMJ0YF"
  };

  const app = initializeApp(firebaseConfig);
  export const db = getFirestore(app);