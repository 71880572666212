import './App.css';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc} from "firebase/firestore";
import { useEffect, useState,} from "react"
import {Route, Routes} from "react-router-dom"
import Reslist from "./Reslist.js";
import ProductInfo from "./productInfo.js";
import ProductDetail from "./productDetail.js"
import ShishaInfo from "./shishaInfo.js"
import LongdrinksInfo from "./longdrinksInfo.js"
import BBlogo from "./assets/BB_Logo.png"


function App() {

  return (
    <div className="bg-black text-white">
      
      <Routes>
      <Route path="/" element={<Reslist />}/>
     
      <Route path="/:id" element={<ProductInfo />}/>
      <Route path="/:id/:product" element={<ProductDetail />}/>
      <Route path="/7_Longdrinks" element={<LongdrinksInfo />}/>

      </Routes>
      <div className="w-screen border-b-2 border-zinc-600"></div>
      <div className="h-screen ">
      <div className="w-screen border-b-2 border-zinc-600"></div>
      <div className=" bg-black flex justify-between p-5 text-yellow-500 font-bold">
      <div><a href="https://./b/classic-speisekarte.appspot.com/o/Impressum.pdf?alt=media&token=62cd37fa-40c7-4ea8-9cf6-974324b07e76&_gl=1*ym397f*_ga*NDk1MDEzMTkuMTY5NzUyOTkxMw..*_ga_CW55HF8NVT*MTY5NzUzMjk1My4xMC4xLjE2OTc1MzMwOTEuNjAuMC4w">Impressum</a></div>
      <div><a href="https://..com//b/classic-speisekarte.appspot.com/o/DATENSCHUTZ.pdf?alt=media&token=60536915-3370-482d-adee-2bdc77297a52">Datenschutzerklärung</a></div>
      <div><a href="https://..com/v0/b/classic-speisekarte.appspot.com/o/AGB.pdf?alt=media&token=d03fabdf-4ed1-4bf8-b621-a4f67568a8a2">AGB's</a></div>
      </div>
      </div>
    </div>
  );
}

export default App;
